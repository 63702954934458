import { useState } from 'react';
import "./Search.css"
import Card from 'react-bootstrap/Card'
import ConfigData from '../../config.json';
import noImage from '../../assets/images/certificate_default.jpg'
import ClickableDiv from 'react-clickable-div'
import 'react-clickable-div/dist/index.css'
const Search = () => {
  const [searchValue, setSearChValue] = useState("")
  const [candidates, setCandidates] = useState([])
  const handleOnChange = event => {
    setSearChValue(event.target.value);
  };
  const makeApiCall = searchInput => {
    var searchUrl = `${ConfigData.API_URL}/candidate`;
    fetch(searchUrl)
      .then(response => {
        return response.json();
      })
      .then(jsonData => {
        console.log(jsonData);
        let filteredData = searchValue ? jsonData.filter(x => x.certNo.toLowerCase() === searchValue.toLowerCase() 
        || x.email.toLowerCase() === searchValue.toLowerCase()
        || x.studentId.toLowerCase() === searchValue.toLowerCase() ) : []
        setCandidates(filteredData)
      });
  };
  const handleSearch = () => {
    makeApiCall(searchValue);
  };

  const keyPress = (e) =>{
    if(e.keyCode == 13){
       console.log('value', e.target.value);
       makeApiCall( e.target.value);
    }
 }


  
  console.log("candidates", candidates)
  
  return (
    <div>
      {/* <div className="serach-container">
        <input name="text" type="text" placeholder="Please enter Certificate number"
          onChange={event => handleOnChange(event)}
          value={searchValue}
        />
        <button onClick={handleSearch} className="mt-3">Search</button>
      </div> */}

<div className="main ">
  {/* <div className="form-group has-search">
    <span className="fa fa-search form-control-feedback"></span>
    <input type="text" class="form-control" placeholder="Search"/>
  </div> */}
  
  <div className="input-group">
    <input type="text" onKeyDown={event => keyPress(event)} className="form-control search-input"
     onChange={event => handleOnChange(event)}
     value={searchValue}
    placeholder="Please enter Certificate number to search."/>
    <div className="input-group-append">
      <button onClick={handleSearch} className="btn btn-secondary" type="button">
        <i className="fa fa-search"></i>
      </button>
    </div>
  </div>
</div>





      <div >
        {candidates.length !=0 ? (
          <div className=" container d-flex flex-wrap ">
            {candidates.map((candidate, index) => (
              <div key={index} >
  
                {/* <Card style={{ width: '18rem', margin: "2rem" }}>
                  <Card.Body>
                    <p>Name : <span className="data">{candidate.name} </span></p>
                    <p>Course enrolled : <span className="data">{candidate.subject} </span></p>
                    <p>Certificate Number : <span className="data">{candidate.certNo} </span></p>
                    <p>Enrollment Date : <span className="data">{candidate.startDate} </span></p>
                    <p>Completion Date :   <span className="data"> {candidate.endDate}</span></p>
                    <a href={candidate.imageUrl}>Download Certificate </a>
                  </Card.Body>
                </Card> */}
                 <div className="card" style={{width: 500}}>
            <div className="row no-gutters">
              <div   onClick={() => { window.open(`${candidate.imageUrl}${ConfigData.AZURE_BLOB_SAS_URL}`, '_blank').focus()}} className="col-sm-5 my-awesome-div transparent-button" style={{margin:'auto'}}>
                    {/* <img className="card-img" src={noImage} alt="Suresh Dasari Card"/> */}
                 
                    <embed  style={{cursor:"pointer", pointerEvents: "none"}}  src={`${candidate.imageUrl}${ConfigData.AZURE_BLOB_SAS_URL}`} type="application/pdf" width="100%" height="165px" />
                  
                </div>
                <div className="col-sm-7">
                    <div className="card-body">
                        <h5 className="card-title">{candidate.name}</h5>
                        <p className="card-text">Certificate Number: {candidate.certNo}</p>
                        <p className="card-text"> Program Name: {candidate.subject}</p>
                        <p className="card-text"> Date of completion: {candidate.startDate}</p>
                        <p className="card-text"> Date of certificate issued: {candidate.endDate}</p>
                        {/* <a href="#" className="btn btn-primary">View Profile</a> */}
                    </div>
                </div>
            </div>
        </div>

              </div>
            ))}
          </div>
        ) : (
          <p className="no-resultp">No result found</p>
        )}
      </div>
    </div>
  )
}

export default Search;