//import logo from './logo.svg';
import Search from './Screens/Search/Search'
import './App.css';
function App() {
  return (
   <Search></Search>
  );
}

export default App;
